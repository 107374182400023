import type { CssStyleObject } from '~/types';

export function useColorSwatches() {
    const gradDir = 'to top left';

    function processColor(newColor: string | string[] | unknown): CssStyleObject | void {
        if (newColor) {
            if (!Array.isArray(newColor)) {
                newColor = [newColor];
            }

            if (Array.isArray(newColor)) {
                return getMultiColorCssObj(newColor);
            }
        }
    }

    function getMultiColorCssObj(color: string[]): CssStyleObject {
        switch (color.length) {
            case 1:
                return { 'background-color': String(color[0]).toLowerCase() };
            case 2:
                return {
                    'background-image': `linear-gradient(${gradDir}, ${color[0]} calc(50% - 1px), ${color[1] || 'transparent'} calc(50% + 1px))`
                };
            default:
                // 3 and over
                return {
                    'background-image': `linear-gradient(${gradDir}, ${color[0]} calc(33% - 1px), ${color[1]} 33%, ${color[1]} 66%, ${color[2]} calc(66% + 1))`
                };
        }
    }

    return {
        processColor,
        getMultiColorCssObj
    };
}
